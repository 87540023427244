<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="input_search">
          <input
            type="text"
            placeholder="제품명 또는 규격 검색"
            :value="searchStockText"
            @input.prevent="setSearchText($event)"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input_text">
          <label>창고</label>
          <my-local-selectric
            :id="'product_store_selectric'"
            :watch="searchStoreLocation"
            :options="store_options_all"
            @changeValue="searchStore($event)"
          >
          </my-local-selectric>
        </div>
        <div class="input_text">
          <label>구분</label>
          <my-local-selectric
            :id="'product_division_selectric'"
            :watch="searchProductResource"
            :options="product_resource_options"
            @changeValue="searchResource($event)"
          >
          </my-local-selectric>
        </div>
        <div class="input_checkbox">
          <label for="checkbox1" class="label_txt">재고량 0 제외</label>
          <input type="checkbox" id="checkbox1" @click="exceptStockZero" />
          <label
            for="checkbox1"
            class="chk_box"
            :class="{ active: chkStockZero }"
          >
            <i class="fa fa-check"></i>
          </label>
        </div>
        <button class="excel_btn" @click="exportExcel">엑셀로 출력</button>
      </div>
      <div class="search_length">
        <h6>조회수 : {{ filterProductTotalStock.length }}건</h6>
        <h6>조회수 : {{ filteredProductControl.length }}건</h6>
      </div>
      <div>
        <div class="mes_tbl_wrap total_list">
          <table class="mes_tbl">
            <colgroup>
              <col v-for="(n, index) in 4" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>제품명</th>
                <th>규격</th>
                <th>재고량(단위)</th>
                <th>그래프</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stockStatus, index) in filterProductTotalStock"
                :key="stockStatus.id"
                @click="selectRow(index)"
                :class="{ active: selectedIndex == index }"
              >
                <td class="text_left">
                  {{ findInfoFromId(product, stockStatus.product_id).name }}
                </td>
                <td class="text_left">
                  {{ findInfoFromId(product, stockStatus.product_id).standard }}
                </td>
                <td class="text_right">
                  {{
                    `${$makeComma(
                      findInfoFromId(productTotalStock, stockStatus.product_id)
                        .stock_quantity,
                    )}(${
                      unitCodes.length > 0 && product.length > 0
                        ? findInfoFromId(
                            unitCodes,
                            findInfoFromId(product, stockStatus.product_id)
                              .stock_unit_id,
                          ).name
                        : ''
                    })`
                  }}
                </td>
                <td>
                  <button class="btn_tbl" @click="openModal(index)">
                    그래프
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mes_tbl_wrap control_list">
          <table class="mes_tbl" :class="{ manage: managementMode }">
            <colgroup>
              <col v-for="(n, index) in managementMode ? 8 : 7" :key="index" />
            </colgroup>
            <thead>
              <tr>
                <th>입고일시</th>
                <th>거래처명</th>
                <th>제품명{{ systemCompany.expire_yn ? `(유통기한)` : '' }}</th>
                <th>규격</th>
                <th>생산식별표</th>
                <th>창고명</th>
                <th>재고량(단위)</th>
                <th v-if="managementMode">재고수정</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(selectStock, index) in filteredProductControl"
                :key="selectStock.id"
              >
                <td>
                  {{
                    selectStock.create_time.substr(0, 19) | formatDateNoHours
                  }}
                </td>
                <td class="text_left">
                  {{
                    stockStatusList != undefined &&
                    stockStatusList != null &&
                    stockStatusList.length > 0
                      ? stockStatusList.find(
                          x => x.barcode_num == selectStock.product_ident,
                        ).company_name
                      : ''
                  }}
                </td>
                <td class="text_left">
                  {{ findInfoFromId(product, selectStock.product_id).name
                  }}<br />{{
                    systemCompany.expire_yn
                      ? `(${getExpireDate(selectStock)}까지)`
                      : ''
                  }}
                </td>
                <td class="text_left">
                  {{ findInfoFromId(product, selectStock.product_id).standard }}
                </td>
                <td>
                  {{ `P${makeProductBarcode(selectStock)}` }}
                </td>
                <td>{{ findInfoFromId(stores, selectStock.store_id).name }}</td>
                <td class="text_right" v-if="!selectStock.is_modify">
                  <b>{{ `${$makeComma(selectStock.quantity)}` }}</b>
                  {{ `(${getFilteredControlUnitName(index)})` }}
                </td>
                <td class="input_manage" v-else>
                  <input
                    type="text"
                    inputmode="decimal"
                    :value="$makeComma(selectStock.quantity_temp)"
                    @input="
                      $inputNumber(
                        $event,
                        selectStock,
                        'quantity_temp',
                        99999999,
                        0,
                      )
                    "
                  />
                </td>
                <td v-if="managementMode">
                  <button class="btn_tbl" @click="changeStock(selectStock)">
                    {{ selectStock.is_modify ? '완료' : '수정' }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <product-graph-modal
      v-if="isGraphModalOpen"
      :filteredStockStatus="filteredStockStatus"
      :productTotalStock="filterProductTotalStock"
      :key="key"
      @redraw="key += 1"
      @onclose="isGraphModalOpen = false"
      @changedate="changeModalDate($event)"
      :startDate="modal_start_date"
      :endDate="modal_end_date"
    ></product-graph-modal>
    <div class="modal_layer" v-if="isGraphModalOpen" @click="closeModal"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XLSX from 'xlsx';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import ProductGraphModal from '@/layouts/components/ProductGraphModal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import {
  getExpireDate,
  yyyymmdd,
  yyyy년MM월dd일HH시mm분ss초,
} from '@/utils/func';

export default {
  mixins: [fetchMixin, modalMixin],
  components: {
    ProductGraphModal,
    MyLocalSelectric,
  },
  data() {
    return {
      isGraphModalOpen: false,
      key: 0,
      modal_start_date: null,
      modal_end_date: null,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromProductStatusPage',
      searchStockText: 'getSearchStockTextFromProductStockStatus',
      stockStatusList: 'getProductStockStatusListFromProductStockStatus',
      productTotalStock: 'getProductTotalStock',
      productInOutType: 'getProductInOutType',
      productControlList: 'getProductControl',
      product: 'getProduct',
      unitCodes: 'getUnitCodes',
      stores: 'getStores',
      selectedIndex: 'getSelectedIndexFromProductStock',
      store_options_all: 'getStoresForSelectricAll',
      product_resource_options: 'getProductTypeForSelectricAll',
      searchStoreLocation: 'getSearchStoreLocationFromProductStockStatus',
      searchProductResource: 'getSearchProductResourceFromProductStock',
      chkStockZero: 'getCheckExceptStorckZeroFromProductStockStatus',
      systemCompany: 'getSystemCompany',
      printCompleteList: 'getPrintCompleteListFromPrintComplete',
    }),
    filterProductTotalStock() {
      if (this.productTotalStock !== undefined) {
        const Hangul = require('hangul-js');
        return this.productTotalStock
          .filter(
            x =>
              this.findInfoFromId(this.product, x.product_id).name.includes(
                this.searchStockText,
              ) ||
              Hangul.d(
                this.findInfoFromId(this.product, x.product_id).name,
                true,
              )
                .map(x => x[0])
                .join('')
                .includes(this.searchStockText) ||
              this.findInfoFromId(this.product, x.product_id).standard.includes(
                this.searchStockText,
              ) ||
              Hangul.d(
                this.findInfoFromId(this.product, x.product_id).standard,
                true,
              )
                .map(x => x[0])
                .join('')
                .includes(this.searchStockText),
          )
          .filter(
            x =>
              this.productControlList.filter(
                y =>
                  y.product_id == x.product_id &&
                  (y.store_id == this.searchStoreLocation ||
                    this.searchStoreLocation == null),
              ).length > 0,
          )
          .filter(
            x =>
              this.findInfoFromId(this.product, x.product_id)
                .resource_type_id == this.searchProductResource ||
              this.searchProductResource == null,
          )
          .filter(x =>
            this.chkStockZero ? x.stock_quantity > 0 : x.stock_quantity >= 0,
          );
      } else {
        return [];
      }
    },
    filteredStockStatus() {
      if (this.stockStatusList !== undefined && this.selectedIndex != -1) {
        const filterList = this.stockStatusList
          .filter(
            x =>
              x.product_id ==
                this.filterProductTotalStock[this.selectedIndex].product_id &&
              x.input_date >= this.modal_start_date,
          )
          .sort(
            (a, b) =>
              this.$makeNumber(b.input_date.replace(/-/gi, '')) -
              this.$makeNumber(a.input_date.replace(/-/gi, '')),
          );
        console.log('filterList', filterList);
        let list = [];
        filterList.forEach(x => {
          const item = list.find(y => y.date == x.input_date);
          if (item == undefined) {
            if (
              this.productInOutType
                .filter(
                  x => x.detail == 'process in' || x.detail == 'return in',
                )
                .map(x => x.id)
                .includes(x.product_in_out_type_id)
            ) {
              const inType = this.productInOutType.find(
                y => y.id == x.product_in_out_type_id,
              ).detail;

              list.push({
                date: x.input_date,
                date_label: x.input_date,
                quantity: this.$makeComma(x.quantity),
                company_label: [
                  `[${x.input_date} ${
                    inType == 'process in' ? '입고' : '반품입고'
                  }] ${x.company_name} ${this.$makeComma(x.quantity)}`,
                ],
              });
            } else {
              list.push({
                date: x.input_date,
                date_label: x.input_date,
                quantity: -x.quantity,
                company_label: [
                  `[${x.input_date} 출고] ${this.$makeComma(x.quantity)}`,
                ],
              });
            }
          } else {
            if (
              this.productInOutType
                .filter(
                  x => x.detail == 'process in' || x.detail == 'return in',
                )
                .map(x => x.id)
                .includes(x.product_in_out_type_id)
            ) {
              const inType = this.productInOutType.find(
                y => y.id == x.product_in_out_type_id,
              ).detail;

              item.quantity = this.$decimalAdd(
                this.$makeNumber(item.quantity),
                this.$makeNumber(x.quantity),
              );
              item.company_label.push(
                `[${x.input_date} ${
                  inType == 'process in' ? '입고' : '반품입고'
                }] ${x.company_name} ${this.$makeComma(x.quantity)}`,
              );
            } else {
              item.quantity = this.$decimalSub(
                this.$makeNumber(item.quantity),
                this.$makeNumber(x.quantity),
              );
              item.company_label.push(
                `[${x.input_date} 출고] ${this.$makeComma(x.quantity)}`,
              );
            }
          }
        });

        list.push({
          date: '1700-01-01',
          date_label: '전 재고',
          currentStock: 0,
          quantity: 0,
        });
        let total = this.lodash.clonedeep(
          this.$makeNumber(
            this.filterProductTotalStock[this.selectedIndex].stock_quantity,
          ),
        );
        console.log('list', list);
        console.log('total', total);

        list.forEach((x, index) => {
          if (index != list.length - 1) {
            if (index != 0) {
              total = this.$decimalSub(
                total,
                this.$makeNumberFixed2(x.quantity),
              );
              list[index + 1].currentStock = this.lodash.clonedeep(total);
            } else {
              x.currentStock = this.$makeNumber(total);
              total = this.$decimalSub(
                total,
                this.$makeNumberFixed2(x.quantity),
              );
              list[index + 1].currentStock = this.lodash.clonedeep(total);
            }
          }
        });

        return list
          .sort(
            (a, b) =>
              this.$makeNumber(a.date.replace(/-/gi, '')) -
              this.$makeNumber(b.date.replace(/-/gi, '')),
          )
          .filter(x => x.date <= this.modal_end_date);
      } else {
        return [];
      }
    },
    filteredProductControl() {
      if (this.productControlList !== undefined && this.selectedIndex != -1) {
        return this.productControlList
          .filter(
            x =>
              x.product_id ==
                this.filterProductTotalStock[this.selectedIndex].product_id &&
              this.$makeNumber(x.quantity) > 0,
          )
          .filter(
            x =>
              x.store_id == this.searchStoreLocation ||
              this.searchStoreLocation == null,
          )
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      } else {
        return [];
      }
    },
  },
  methods: {
    getExpireDate(item) {
      console.log('in getExpireDate');
      const products = this.findInfoFromId(this.product, item.product_id);
      console.log('foundProduct', products);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    async changeStock(item) {
      if (item.is_modify) {
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_PRODUCT_CONTROL_STOCK', {
            id: item.id,
            quantity: this.$makeNumber(item.quantity_temp),
          })
          .then(() => {
            item.quantity = this.$makeNumber(item.quantity_temp);
            this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('재고 수정 실패', '재고 수정 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
      item.is_modify = !item.is_modify;
    },
    changeModalDate(e) {
      this.modal_start_date = e.start_date;
      this.modal_end_date = e.end_date;
      this.key += 1;
    },
    setSearchText(e) {
      this.$store.commit(
        'setSearchStockTextToProductStockStatus',
        e.target.value,
      );
      this.$store.commit('setSelectedIndexToProductStock', -1);
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToProductStock', index);
    },
    getUnitName(index) {
      const ProductStatusStockTemp = this.productTotalStock[index].product_id;
      const findMId = this.findInfoFromId(this.product, ProductStatusStockTemp);
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.unitCodes,
          findMId.incoming_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
    getFilteredControlUnitName(index) {
      const ProductStatusStockTemp = this.filteredProductControl[index]
        .product_id;
      const findMId = this.findInfoFromId(this.product, ProductStatusStockTemp);
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.unitCodes,
          findMId.stock_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
    async openModal(index) {
      await this.selectRow(index);
      this.isGraphModalOpen = true;
    },
    closeModal() {
      this.isGraphModalOpen = false;
    },
    searchStore(e) {
      this.selectRow(-1);
      this.$store.commit('setSearchStoreLocationToProductStock', e);
    },
    searchResource(e) {
      this.selectRow(-1);
      this.$store.commit('setSearchProductResourceToProductStock', e);
    },
    exceptStockZero() {
      const selectListTemp = this.filterProductTotalStock[this.selectedIndex];

      this.$store.commit(
        'setCheckExceptStockZeroToProductStock',
        !this.chkStockZero,
      );

      if (this.selectedIndex != -1) {
        const findIndex = this.lodash.clonedeep(
          this.filterProductTotalStock.findIndex(
            x => x.product_id == selectListTemp.product_id,
          ),
        );

        this.selectRow(findIndex);
      }
    },
    makeProductBarcode(product) {
      const completeList = this.printCompleteList.find(
        x => x.product_control_id == product.id,
      );

      if (completeList.input_date != undefined) {
        const date = completeList.input_date.replace(/-/gi, '');
        const product_control_id = completeList.product_control_id;
        const fix5 = ('00000' + product_control_id).substr(-5);
        completeList.barcode_num = date + fix5;
        return date + fix5;
      }
    },
    exportExcel() {
      let stockList = [];
      let searchProductName = '전체';

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 6 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 6 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 6 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        [],
        ['조회 원자재명', searchProductName],
        [],
        [],
        [],
      ];

      stockList = stockList.concat(standardInfo);

      stockList.push(['원자재명', '규격', '재고량(단위)']);

      this.filterProductTotalStock.forEach(el => {
        stockList.push([
          this.findInfoFromId(this.product, el.product_id).name,
          this.findInfoFromId(this.product, el.product_id).standard,
          this.$makeComma(
            this.findInfoFromId(this.productTotalStock, el.product_id)
              .stock_quantity,
          ) +
            `(${
              this.findInfoFromId(
                this.unitCodes,
                this.findInfoFromId(this.product, el.product_id).stock_unit_id,
              ).name
            })`,
        ]);
      });
      let wb = XLSX.utils.book_new();

      let stock = XLSX.utils.json_to_sheet(stockList, { skipHeader: true });

      stock['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, stock, '재고');

      let title = '원자재재고내역_(' + searchProductName + ').xls';

      XLSX.writeFile(wb, title);
    },
  },
  watch: {
    managementMode(newValue) {
      if (!newValue) {
        const controlListTemp = this.lodash.clonedeep(this.productControlList);
        this.$store.commit('setProductControl', controlListTemp);
      }
    },
  },
  async created() {
    if (this.product.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.store_options_all.length < 2) {
      await this.FETCH('FETCH_STORE', '창고');
    }
    if (this.productInOutType.length < 1) {
      await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '자재 입출고 유형');
    }
    if (this.product_resource_options.length < 2) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원 종류');
    }

    await this.FETCH('FETCH_PRODUCT_STATUS', '제품 현황');
    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '재고 현황');
    await this.FETCH('FETCH_PRODUCT_CONTROL', '재고 관리');
    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_PRINT_COMPLETE',
      {
        start: '2001-01-01',
        end: yyyymmdd(new Date()),
      },
      '식별표정보',
    );

    if (this.modal_start_date == null) {
      let date = new Date();
      let date2 = new Date();
      date2 = new Date(date2.setDate(date2.getDate() - 7));
      this.modal_start_date = yyyymmdd(date2);
      this.modal_end_date = yyyymmdd(date);
    }

    if (this.$route.meta.stock != undefined) {
      const stock = this.lodash.clonedeep(this.$route.meta.stock);
      this.filterProductTotalStock.forEach((x, index) => {
        if (x.product_id == stock) {
          this.selectRow(index);
        }
      });
      delete this.$route.meta.stock;
    }
  },
};
</script>

<style lang="scss" scoped></style>
