<template>
  <div
    id="contents"
    :class="tabIndex == 0 ? 'raw_products_status' : 'stock_input_status'"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToProductStatusPage'"
          :options="[
            { title: '제품 입고 현황', name: 'product_input_status' },
            { title: '재고&출고 현황', name: 'stock_output_status' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="
              $store.commit(
                'setManagementModeToProductStatusPage',
                !managementMode,
              )
            "
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex == 1"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">제품 입출고현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">제품 재고현황</a>
          </li>
        </ul>
      </div>
      <product-status-form v-if="tabIndex == 0"></product-status-form>
      <product-stock-status-form
        v-if="tabIndex == 1"
      ></product-stock-status-form>
    </div>
  </div>
</template>

<script>
import ProductStatusForm from '@/views/forms/Product/ProductStatus/ProductStatusForm';
import ProductStockStatusForm from '@/views/forms/Product/ProductStatus/ProductStockStatusForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    ProductStatusForm,
    ProductStockStatusForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromProductStatusPage',
      managementMode: 'getManagementModeFromProductStatusPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToProductStatusPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitProductStatusPage');
      this.$route.meta.menu_init = false;
    }
    if (this.$route.meta.stock != undefined) {
      this.SetOpenTabIndex(1);
    }
  },
};
</script>

<style lang="scss" scoped></style>
