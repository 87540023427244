<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span>입출고일자</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="startDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="endDate"
                @change="setEndDate($event)"
              />
            </div>
            <div class="input_search">
              <input
                type="text"
                placeholder="거래처 검색"
                :value="companyText"
                @input="setCompanyText($event)"
              />
              <button>
                <i class="fa fa-search"></i>
              </button>
            </div>
            <div class="input_search">
              <input
                type="text"
                placeholder="제품명 또는 규격 검색"
                :value="searchText"
                @input="setSearchText($event)"
              />
              <button>
                <i class="fa fa-search"></i>
              </button>
            </div>
            <button class="excel_btn" @click="exportExcel">엑셀로 출력</button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filteredProductStatus.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 9" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>구분</th>
              <th>입출고일자</th>
              <th>거래처명</th>
              <th>제품명{{ systemCompany.expire_yn ? ` (유통기한)` : '' }}</th>
              <th>규격</th>
              <th>생산식별표</th>
              <th>LOT번호</th>
              <th>수량(단위)</th>
              <th>투입공정</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="productStatus in filteredProductStatus"
              :key="productStatus.id"
              :class="
                target != undefined &&
                target != null &&
                target.ident_value == 'L' + productStatus.barcode_num
                  ? 'active'
                  : ''
              "
            >
              <!-- 구분 -->
              <td>
                {{
                  findInfoFromId(
                    productInOutType,
                    productStatus.product_in_out_type_id,
                  ).name
                }}
              </td>
              <!-- 거래처명 -->
              <td>{{ productStatus.create_time | formatDateNoHours }}</td>
              <td class="text_left">{{ productStatus.company_name }}</td>
              <td class="text_left">
                {{
                  productStatus.product_name +
                    (systemCompany.expire_yn
                      ? ` (${getExpireDate(productStatus)} 까지)`
                      : '')
                }}
              </td>
              <td class="text_left">{{ productStatus.product_standard }}</td>
              <td>{{ `P${makeProductBarcode(productStatus)}` }}</td>
              <td>
                {{ `L${productStatus.barcode_num}`
                }}<button
                  class="btn_tbl"
                  @click="trackingLotNum(productStatus.barcode_num)"
                >
                  추적
                </button>
              </td>
              <td class="text_right">
                {{
                  `${$makeComma(productStatus.quantity)}(${
                    findInfoFromId(
                      unitCodes,
                      findInfoFromId(product, productStatus.product_id)
                        .stock_unit_id,
                    ).name
                  })`
                }}
              </td>
              <td>
                {{
                  productStatus.product_in_out_type_id ==
                  productInOutType.find(x => x.detail == 'using out').id
                    ? `L${productStatus.output_lot_number}`
                    : ''
                }}<button
                  v-show="
                    productStatus.product_in_out_type_id ==
                      productInOutType.find(x => x.detail == 'using out').id
                  "
                  class="btn_tbl"
                  @click="trackingLotNum(productStatus.output_lot_number)"
                >
                  추적
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import { getExpireDate, yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import routes from '@/routes/routes';

export default {
  mixins: [fetchMixin, modalMixin],
  data() {
    return {
      target: null,
    };
  },
  computed: {
    ...mapGetters({
      startDate: 'getStartDateFromProductStatus',
      endDate: 'getEndDateFromProductStatus',
      date: 'getDateFromProductStatus',
      searchText: 'getSearchTextFromProductStatus',
      companyText: 'getCompanyTextFromProductStatus',
      productStatusList: 'getProductStatusListFromProductStatus',
      product: 'getProduct',
      unitCodes: 'getUnitCodes',
      productInOutType: 'getProductInOutType',
      systemCompany: 'getSystemCompany',
      printCompleteList: 'getPrintCompleteListFromPrintComplete',
    }),
    filteredProductStatus() {
      if (this.productStatusList !== undefined) {
        const Hangul = require('hangul-js');
        return this.productStatusList
          .filter(
            x =>
              x.product_name.includes(this.searchText) ||
              Hangul.d(x.product_name, true)
                .map(x => x[0])
                .join('')
                .includes(this.searchText) ||
              x.product_standard.includes(this.searchText) ||
              Hangul.d(x.product_standard, true)
                .map(x => x[0])
                .join('')
                .includes(this.searchText),
          )
          .filter(
            x =>
              x.company_name.includes(this.companyText) ||
              Hangul.d(x.company_name, true)
                .map(x => x[0])
                .join('')
                .includes(this.companyText),
          )
          .sort(
            (a, b) =>
              new Date(b.create_time) - new Date(a.create_time) || b.id - a.id,
          );
      } else {
        return [];
      }
    },
  },
  methods: {
    getExpireDate(item) {
      console.log('in getExpireDate');
      const products = this.findInfoFromId(this.product, item.product_id);
      console.log('foundProduct', products);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.product_control_create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    setStartDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setStartDateToProductStatus', e.target.value);
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '입고일자 조회기간 오류',
            `입고일자 조회기간 최후날짜(${this.endDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToProductStatus', e.target.value);
          // this.selectRow(-1);
          this.FETCH_PRODUCT_STATUS_DAY();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToProductStatus', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        // this.$store.commit('setEndDateToProductStatus', e.target.value);
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '입고일자 조회기간 오류',
            `입고일자 조회기간 최초날짜(${this.startDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToProductStatus', e.target.value);
          // this.selectRow(-1);
          this.FETCH_PRODUCT_STATUS_DAY();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToProductStatus', yyyymmdd(new Date()));
      }
    },
    setSearchText(e) {
      this.$store.commit('setSearchTextToProductStatus', e.target.value);
    },
    setCompanyText(e) {
      this.$store.commit('setCompanyTextToProductStatus', e.target.value);
    },

    getUnitName(index) {
      const ProductStatusTemp = this.productStatusList[index].product_id;
      const findMId = this.findInfoFromId(this.product, ProductStatusTemp);
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.unitCodes,
          findMId.incoming_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
    FETCH_PRODUCT_STATUS_DAY() {
      this.$store
        .dispatch('FETCH_PRODUCT_STATUS_DAY', {
          start: this.startDate,
          end: this.endDate,
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '제품입고현황 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {});
    },
    exportExcel() {
      let inOutList = [];
      let date = this.startDate + '~' + this.endDate;
      let searchProductName = '';
      if (this.searchText == '') {
        searchProductName = '전체';
      } else {
        const searchData = new Set(
          this.filteredProductStatus.map(x => x.product_name),
        );
        searchProductName = [...searchData].join(', ');
      }

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 8 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 8 } },
        { s: { r: 3, c: 1 }, e: { r: 3, c: 8 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        ['조회 제품명', searchProductName],
        [],
        [],
        [],
      ];

      inOutList = inOutList.concat(standardInfo);

      inOutList.push([
        '',
        '구분',
        '거래처명',
        '제품명',
        '규격',
        '생산식별표',
        'LOT번호',
        '수량(단위)',
        '투입공정',
      ]);

      this.filteredProductStatus.forEach(el => {
        inOutList.push([
          el.input_date,
          this.findInfoFromId(this.productInOutType, el.product_in_out_type_id)
            .name,
          el.company_name,
          el.product_name,
          el.product_standard,
          'P' + this.makeProductBarcode(el),
          'L' + el.barcode_num,
          this.$makeComma(el.quantity) +
            ' (' +
            this.findInfoFromId(
              this.unitCodes,
              this.findInfoFromId(this.product, el.product_id).stock_unit_id,
            ).name +
            ')',
          el.product_in_out_type_id ==
          this.productInOutType.find(x => x.detail == 'using out').id
            ? `L${el.output_lot_number}`
            : '',
        ]);
      });
      let wb = XLSX.utils.book_new();

      let dataInOut = XLSX.utils.json_to_sheet(inOutList, { skipHeader: true });

      dataInOut['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, dataInOut, '입출고');

      let title =
        '제품입출고내역(' + date + ')_(' + searchProductName + ').xls';

      XLSX.writeFile(wb, title);
    },
    makeProductBarcode(product) {
      const completeList = this.printCompleteList.find(
        x => x.product_control_id == product.product_control_id,
      );

      if (completeList.input_date != undefined) {
        const date = completeList.input_date.replace(/-/gi, '');
        const product_control_id = completeList.product_control_id;
        const fix5 = ('00000' + product_control_id).substr(-5);
        completeList.barcode_num = date + fix5;
        return date + fix5;
      }
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
  },
  async created() {
    if (this.$route.meta.target != undefined) {
      this.target = this.$route.meta.target;
      var date = new Date(this.target.create_time.substr(0, 10));
      this.$store.commit('setEndDateToProductStatus', yyyymmdd(date));
      var date2 = new Date(this.target.create_time.substr(0, 10));
      this.$store.commit('setStartDateToProductStatus', yyyymmdd(date2));
      delete this.$route.meta.target;
    } else if (this.startDate == null) {
      var date3 = new Date();
      this.$store.commit('setEndDateToProductStatus', yyyymmdd(date3));
      var date4 = new Date(date3.setDate(date3.getDate() - 7));
      this.$store.commit('setStartDateToProductStatus', yyyymmdd(date4));
    }

    await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    await this.FETCH('FETCH_UNIT', '단위');
    await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '자재 입출고 유형');
    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_PRINT_COMPLETE',
      {
        start: '2001-01-01',
        end: yyyymmdd(new Date()),
      },
      '식별표정보',
    );
    await this.FETCH_PRODUCT_STATUS_DAY();
  },
};
</script>

<style lang="scss" scoped></style>
